import * as Analytics from './analytics.js';
import * as URLBuild from './url_builder.js';

export function genericLinkHandlerInit() {
  let linkElements = document.querySelectorAll('a');

  linkElements.forEach((el) => {
    el.addEventListener('click', (event) => {
      let thisEl = event.currentTarget;
      let eventCategory = thisEl.dataset.eventcategory;
      let eventAction = thisEl.dataset.eventaction;

      if (eventCategory && eventAction) {
        Analytics.trackEvent(eventCategory, eventAction);
      }
    });
  });
}

export function huntTheJobInit() {
  const buttonHunt = document.querySelector('#hunt-the-job-start');

  if (buttonHunt) {
    buttonHunt.addEventListener('click', (event) => {
      const CHECK_BUILDERS = [{
        selector: '#hunt-the-job-stackoverflow',
        analyticsLabel: 'StackOverflow'
      }, {
        selector: '#hunt-the-job-indeed',
        analyticsLabel: 'Indeed'
      }, {
        selector: '#hunt-the-job-linkedin',
        analyticsLabel: 'LinkedIn'
      }, {
        selector: '#hunt-the-job-glassdoor',
        analyticsLabel: 'GlassDoor'
      }, {
        selector: '#hunt-the-job-dribbble',
        analyticsLabel: 'Dribbble'
      }, {
        selector: '#hunt-the-job-monster',
        analyticsLabel: 'Monster'
      }, {
        selector: '#hunt-the-job-careerbuilder',
        analyticsLabel: 'CareerBuilder'
      }, {
        selector: '#hunt-the-job-usajobs',
        analyticsLabel: 'UsaJobs'
      }, {
        selector: '#hunt-the-job-dice',
        analyticsLabel: 'Dice'
      }, {
        selector: '#hunt-the-job-coderwall',
        analyticsLabel: 'CoderWall'
      }, {
        selector: '#hunt-the-job-weworkremotely',
        analyticsLabel: 'WeWorkRemotely'
      }, {
        selector: '#hunt-the-job-betalist',
        analyticsLabel: 'BetaList'
      }, {
        selector: '#hunt-the-job-authentic',
        analyticsLabel: 'AuthenticJobs'
      }, {
        selector: '#hunt-the-career-amazon',
        analyticsLabel: 'Amazon'
      }, {
        selector: '#hunt-the-career-apple',
        analyticsLabel: 'Apple'
      }, {
        selector: '#hunt-the-career-facebook',
        analyticsLabel: 'Facebook'
      }, {
        selector: '#hunt-the-career-google',
        analyticsLabel: 'Google'
      }, {
        selector: '#hunt-the-career-microsoft',
        analyticsLabel: 'Microsoft'
      }, {
        selector: '#hunt-the-career-netflix',
        analyticsLabel: 'Netflix'
      }, {
        selector: '#hunt-the-career-shopify',
        analyticsLabel: 'Shopify'
      }, {
        selector: '#hunt-the-career-atlassian',
        analyticsLabel: 'Atlassian'
      }, {
        selector: '#hunt-the-career-cisco',
        analyticsLabel: 'Cisco'
      }, {
        selector: '#hunt-the-career-intel',
        analyticsLabel: 'Intel'
      }, {
        selector: '#hunt-the-job-ladders',
        analyticsLabel: 'Ladders'
      }, {
        selector: '#hunt-the-job-startup-jobs',
        analyticsLabel: 'StartupJobs'
      }];

      const inputJobQuery = document.querySelector('#hunt-the-job-what');
      const inputJobLocation = document.querySelector('#hunt-the-job-where');
      const labelJobQueryMissing = document.querySelector('#hunt-the-job-what-missing');

      if (inputJobQuery.value) {
        let howLabel = '';

        inputJobQuery.classList.remove('hunt-the-job-missing-input');
        labelJobQueryMissing.classList.add('hidden');

        CHECK_BUILDERS.forEach((builder) => {
          if (LaunchWindowIfChecked(builder.selector, builder.analyticsLabel,
            inputJobQuery.value, inputJobLocation.value)) {
            howLabel += `${builder.analyticsLabel},`;
          }
        });

        if (howLabel) howLabel = howLabel.slice(0, -1);

        Analytics.trackEvent('HuntTheJob', 'SearchWhat', inputJobQuery.value);
        Analytics.trackEvent('HuntTheJob', 'SearchWhere', inputJobLocation.value);
        Analytics.trackEvent('HuntTheJob', 'SearchHow', howLabel);
      } else {
        inputJobQuery.classList.add('hunt-the-job-missing-input');
        labelJobQueryMissing.classList.remove('hidden');
        Analytics.trackEvent('HuntTheJob', 'MissingInput');
      }
    });
  }
}

function LaunchWindowIfChecked(selector, companyID, role, location) {
  const el = document.querySelector(selector);
  let isChecked = false;

  if (el.checked) {
    const url = URLBuild.companyUrlFromTemplate(companyID, role, location);
    window.open(url, '_blank');
    isChecked = true;
  }

  return isChecked;
}


function setAllSelectLabel(elSelectAll) {
  const selectAllLabel = elSelectAll.labels[0].firstElementChild;

  if (elSelectAll.checked) {
    selectAllLabel.textContent = 'Select None';
  } else {
    selectAllLabel.textContent = 'Select All';
  }
}

function setAllChecksInGroup(groupId, isChecked) {
  const checkJobBoardsNodes = document.querySelectorAll(`${groupId} input`);
  const jobBoardInputs = Array.prototype.slice.call(checkJobBoardsNodes);

  jobBoardInputs.forEach((boardInput) => {
    boardInput.checked = isChecked;
  });

}

export function selectAllInit() {
  const buttonSelectAllBoards = document.querySelector('#select-all-boards');
  if (buttonSelectAllBoards) {
    buttonSelectAllBoards.addEventListener('click', (event) => {
      const selectAllTarget = event.target;

      setAllSelectLabel(selectAllTarget);
      setAllChecksInGroup('#group-job-boards', selectAllTarget.checked);
    });
  }

  let linkElements = document.querySelectorAll('#group-job-boards input');
  linkElements.forEach((el) => {
    el.addEventListener('click', (event) => {
      const checkedCount = document.querySelectorAll('#group-job-boards input[type=checkbox]:checked').length;

      if (checkedCount === 0) {
        buttonSelectAllBoards.checked = false;
      } else {
        buttonSelectAllBoards.checked = true;
      }
      setAllSelectLabel(buttonSelectAllBoards);
    });
  });

  const buttonSelectAllCompanies = document.querySelector('#select-all-companies');
  if (buttonSelectAllCompanies) {
    buttonSelectAllCompanies.addEventListener('click', (event) => {
      const selectAllTarget = event.target;

      setAllSelectLabel(selectAllTarget);
      setAllChecksInGroup('#group-companies', selectAllTarget.checked);
    });
  }

  let linkElementsCompanies = document.querySelectorAll('#group-companies input');
  linkElementsCompanies.forEach((el) => {
    el.addEventListener('click', (event) => {
      const checkedCount = document.querySelectorAll('#group-companies input[type=checkbox]:checked').length;

      if (checkedCount === 0) {
        buttonSelectAllCompanies.checked = false;
      } else {
        buttonSelectAllCompanies.checked = true;
      }
      setAllSelectLabel(buttonSelectAllCompanies);
    });
  });

}
