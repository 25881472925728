const DistanceDefault = 20;

function toTitleCase(str) {
  return str.toLowerCase().replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase()
  });
}

function urlEncode(whatWhere) {
  const what = encodeURIComponent(whatWhere.what);
  const where = encodeURIComponent(whatWhere.where);

  return {what, where};
}

export function companyUrlFromTemplate(companyID, what, where = 'Dallas', distance = DistanceDefault) {
  where = toTitleCase(where);
  const encoded = urlEncode({what, where});

  const companyUrlTemplates = {
    'Amazon': `https://www.amazon.jobs/en/search?base_query=${encoded.what}&loc_query=${encoded.where}&city=${encoded.where}`,
    'Apple': `https://jobs.apple.com/en-us/search?search=${encoded.what}&sort=relevance&location=united-states-USA`,
    'Atlassian': `https://www.atlassian.com/company/careers/all-jobs?team=&location=${encoded.where}&search=${encoded.what}`,
    'Authentic': `https://authenticjobs.com/#location=${encoded.where}&query=${encoded.what}`,
    'BetaList': `https://betalist.com/jobs?q=${encoded.what}&hPP=20&p=0&dFR%5Bcommitment%5D%5B0%5D=Full-Time&dFR%5Bcommitment%5D%5B1%5D=Part-Time&dFR%5Bcommitment%5D%5B2%5D=Contractor&dFR%5Bcommitment%5D%5B3%5D=Internship&is_v=1`,
    'CareerBuilder': `http://www.careerbuilder.com/jobs?keywords=${encoded.what}&location=${encoded.where}&radius=${distance}`,
    'Cisco': `https://jobs.cisco.com/jobs/SearchJobs/${encoded.what}`,
    'CoderWall': `https://coderwall.com/jobs?utf8=%E2%9C%93&q=${encoded.what}&show_fulltime=false&show_fulltime=true&show_parttime=false&show_parttime=true&show_contract=false&show_contract=true&show_remote=false`,
    'Dice': `https://www.dice.com/jobs?q=${encoded.what}&l=${encoded.where}`,
    'Dribbble': `https://dribbble.com/jobs?utf8=%E2%9C%93&keyword=${encoded.what}&category=&location=${encoded.where}&distance=${distance}`,
    'Facebook': `https://www.facebook.com/careers/jobs?q=${encoded.what}`,
    'GlassDoor': `https://www.glassdoor.com/Job/jobs.htm?sc.keyword=${encoded.what}&locT=C&locId=1140045&locKeyword=${encoded.where}`,
    'Indeed': `https://www.indeed.com/jobs?q=${encoded.what}&l=${encoded.where}`,
    'Intel': `https://jobs.intel.com/ListJobs/All/Search/jobtitle/${encoded.what}/`,
    'Ladders': `https://www.theladders.com/jobs/${encoded.what}`,
    'LinkedIn': `https://www.linkedin.com/jobs/search/?keywords=${encoded.what}&location=${encoded.where}`,
    'Shopify': `https://www.shopify.com/careers/search?keywords=${encoded.what}&sort=specialty_asc`,
    'Google': `https://careers.google.com/jobs/results/?company=Google&company=YouTube&location=${encoded.where}&q=${encoded.what}&sort_by=relevance`,
    'Microsoft': `https://careers.microsoft.com/professionals/us/en/search-results?keywords=${encoded.what}`,
    'Monster': `https://www.monster.com/jobs/search/?q=${encoded.what}&where=${encoded.where}&rad=${distance}`,
    'Netflix': `https://jobs.netflix.com/search?q=${encoded.what}`,
    'StackOverflow': `http://stackoverflow.com/jobs?sort=i&q=${encoded.what}&l=${encoded.where}&d=${distance}&u=Miles`,
    'StartupJobs': `https://startup.jobs/?query=${encoded.what}`,
    'UsaJobs': `https://www.usajobs.gov/Search/?keyword=${encoded.what}&Location=${encoded.where}&AutoCompleteSelected=false`,
    'WeWorkRemotely': `https://weworkremotely.com/remote-jobs/search?utf8=%E2%9C%93&term=${encoded.what}`
  };

  return companyUrlTemplates[companyID];
}
